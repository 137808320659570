import {
  HTTP_CP_SignUp_Request,
  HTTP_CP_SignUp_Request_ClientUser,
  HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod,
  HTTP_CP_SignUp_Request_Mortgage,
  HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData,
  HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct,
  HTTP_CP_SignUp_Request_Property,
  HTTP_CP_SignUp_Request_Property_AdditionalPropertyData,
  HTTP_CP_SignUp_Request_Property_PropertyValue,
  HTTP_CP_SignUp_Request_SmartSearch,
  HTTP_CP_SignUp_Request_SmartSearchCase,
} from '@api-new/authservice';
import {
  DATA_Address,
  ENUM_MortgagePartStepType,
  ENUM_MortgageProductPurpose,
  ENUM_MortgageType,
  ENUM_PropertyInvestmentType,
} from '@api-new/common';
import { SignUpState } from '@app/sign-up/models/sign-up-state.model';
import { MortgageFormValues } from '@app/sign-up/utils/mortgage-form';
import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { SmartSearchFormValues } from '@app/sign-up/utils/smart-search-form';
import { CreateUserFormValues } from '@client-ui/components/forms/create-user-form/utils/form-utils';
import { SKIPPED_PRODUCT_MATCH_PLACEHOLDER } from '@client-ui/components/forms/mortgage-product-match-form/mortgage-product-match-form.component';
import dayjs from 'dayjs';

export function signUpStateToHttpRequestAdapter(data: SignUpState): HTTP_CP_SignUp_Request | undefined {
  const clientData = data.user.entity;
  const creationMethod = data.flow.entity.creationMethod;
  const companyId = data.branding.entity?.companyId;

  if (clientData == null || creationMethod == null || companyId == null) {
    return undefined;
  }

  switch (creationMethod) {
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR: {
      return {
        clientUser: getSignUpClientUser(clientData, data.branding.referralCode),
        mortgageMonitor: {
          property: getSignUpPropertyData(data.property.entity),
          mortgage: getSignUpMortgageData(data.mortgage.entity, companyId),
        },
      };
    }

    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER: {
      return {
        clientUser: getSignUpClientUser(clientData, data.branding.referralCode),
        firstTimeBuyer: {
          smartSearch: getSignUpSmartSearchData(
            data.smartSearch.entity,
            ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_FIRST_TIME_BUYER,
          ),
          smartSearchCase: getSignUpSmartSearchCaseData(data.smartSearch.entity),
        },
      };
    }

    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_BUY_TO_LET: {
      return {
        clientUser: getSignUpClientUser(clientData, data.branding.referralCode),
        buyToLet: {
          smartSearch: getSignUpSmartSearchData(
            data.smartSearch.entity,
            undefined,
            ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET,
          ),
          smartSearchCase: getSignUpSmartSearchCaseData(data.smartSearch.entity),
        },
      };
    }

    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_LANDLORD: {
      return {
        clientUser: getSignUpClientUser(clientData, data.branding.referralCode),
        landlord: {
          declaredNumberOfProperties: clientData.numberOfProperties!,
        },
      };
    }

    default: {
      return undefined;
    }
  }
}

function getSignUpClientUser(clientData: CreateUserFormValues, referralCode: string | null): HTTP_CP_SignUp_Request_ClientUser {
  return {
    email: clientData.email,
    phoneNumber: clientData.phoneNumber,
    firstName: clientData.firstName,
    middleName: clientData.middleName.length > 0 ? clientData.middleName : undefined,
    lastName: clientData.lastName,
    dateOfBirth: clientData.dateOfBirth?.toISOString() ?? undefined,
    referralCode: referralCode ?? undefined,
  };
}

function getSignUpPropertyData(property: PropertyFormValues | null): HTTP_CP_SignUp_Request_Property | undefined {
  if (property == null) return undefined;

  const propertyManualAddress: DATA_Address | undefined = property?.address.isManual
    ? {
        postcode: property.address.postcode ?? '',
        buildingNumber: property.address.buildingNumber ?? '',
        buildingName: property.address.buildingName ?? '',
        subBuildingName: '',
        companyName: property.address.companyName ?? '',
        street: property.address.street ?? '',
        dependentStreet: '',
        city: property.address.city ?? '',
      }
    : undefined;

  const addressId: string | undefined =
    typeof property?.address.pickedAddress != 'string' ? property!.address.pickedAddress?.addressId : undefined;

  const propertyValue: HTTP_CP_SignUp_Request_Property_PropertyValue | undefined = property
    ? {
        year: property.propertyValuation.date.getFullYear(),
        month: property.propertyValuation.date.getMonth() + 1,
        manualValue: property.propertyValuation.valuation,
        manualPropertyValueType: property.propertyValuation.valuationSource,
      }
    : undefined;

  const additionalPropertyData: HTTP_CP_SignUp_Request_Property_AdditionalPropertyData | undefined = property.addressDetails
    ? {
        propertyType: property.addressDetails.propertyType ?? undefined,
        constructionYear: property.addressDetails.constructionYear ?? undefined,
        numberOfBedrooms: property.addressDetails.numberOfBedrooms ?? undefined,
        ownershipShare: property.additionalInfo.ownershipShare ?? undefined,
        tenure: property.addressDetails.tenure ?? undefined,
      }
    : undefined;

  return {
    addressId,
    address: propertyManualAddress,
    investmentType: property.investmentType,
    propertyValue,
    additionalPropertyData,
  };
}

function getSignUpMortgageData(mortgage: MortgageFormValues | null, companyId: string): HTTP_CP_SignUp_Request_Mortgage | undefined {
  if (mortgage == null) return undefined;

  return {
    writtenByCompanyId: companyId,
    lenderId: mortgage.lenderAndStartDate.lenderId,
    mortgageParts: mortgage.mortgageParts.parts.map((part, index) => {
      const mortgagePartFromMortgageProduct: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct | undefined =
        mortgage.productMatch.partsMortgageProductIds[index] !== SKIPPED_PRODUCT_MATCH_PLACEHOLDER
          ? {
              mortgageProductId: mortgage.productMatch.partsMortgageProductIds[index],
              startDate: part.startDate?.toISOString(),
              endDate: dayjs(part.startDate).add(part.term, 'month').toISOString(),
            }
          : undefined;

      const mortgagePartFromData: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData | undefined =
        mortgage.productMatch.partsMortgageProductIds[index] === SKIPPED_PRODUCT_MATCH_PLACEHOLDER
          ? {
              initialMortgagePartStepType: part.initialRateType,
              initialPeriodEndDate: dayjs(part.startDate).add(part.initialRatePeriod, 'month').toISOString(),
              startDate: part.startDate?.toISOString(),
              endDate: dayjs(part.startDate).add(part.term, 'month').toISOString(),
              initialInterestRate: part.initialInterestRate,
            }
          : undefined;

      return {
        initialAmount: part.borrowedAmount,
        repaymentType: part.repaymentType,
        mortgagePartFromMortgageProduct,
        mortgagePartFromData,
      };
    }),
  };
}

function getSignUpSmartSearchCaseData(smartSearch: SmartSearchFormValues | null): HTTP_CP_SignUp_Request_SmartSearchCase | undefined {
  if (smartSearch?.mortgageProductId != null)
    return {
      mortgageProductId: smartSearch.mortgageProductId,
    };

  return undefined;
}

function getSignUpSmartSearchData(
  smartSearch: SmartSearchFormValues | null,
  smartSearchPurpose?: ENUM_MortgageProductPurpose,
  investmentType?: ENUM_PropertyInvestmentType,
): HTTP_CP_SignUp_Request_SmartSearch | undefined {
  if (smartSearch == null) return undefined;

  return {
    includeInitialRateTypeFixed: smartSearch.initialRate.initialInterestRateType.includes(
      ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED,
    ),
    includeInitialRateTypeVariable: smartSearch.initialRate.initialInterestRateType.includes(
      ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE,
    ),
    lenderId: smartSearch.liveResults.lenderId ?? undefined,
    minimumInitialRatePeriodInMonths: smartSearch.initialRate.comparisonPeriod[0] * 12,
    maximumInitialRatePeriodInMonths: smartSearch.initialRate.comparisonPeriod[1] * 12,
    mortgageProductPurpose: smartSearchPurpose,
    mortgageType: getSignUpSmartSearchMortgageType(smartSearchPurpose, investmentType),
    preferredComparisonPeriodInMonths: smartSearch.initialRate.comparisonPeriod[0],
    propertyValue: smartSearch.basicDetails.propertyPrice,
    smartSearchParts: [
      {
        deposit: smartSearch.basicDetails.deposit,
        termInMonths: smartSearch.basicDetails.newTermInMonths,
        repaymentType: smartSearch.liveResults.repaymentType,
      },
    ],
  };
}

function getSignUpSmartSearchMortgageType(
  smartSearchPurpose?: ENUM_MortgageProductPurpose,
  investmentType?: ENUM_PropertyInvestmentType,
): ENUM_MortgageType {
  if (investmentType != null) {
    if (investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET) {
      return ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET;
    } else {
      return ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL;
    }
  }

  if (smartSearchPurpose === ENUM_MortgageProductPurpose.MORTGAGE_PRODUCT_PURPOSE_FIRST_TIME_BUYER) {
    return ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL;
  }

  return ENUM_MortgageType.MORTGAGE_TYPE_UNSPECIFIED;
}
